<template>
  <div class="h-full">
    <v-row class="text-center h-full" align="center">
      <v-col>
        <h1 class="font-weight-semibold text--primary d-flex align-center justify-center">
          <span class="me-2">Cette page est introuvable</span>
        </h1>
        <p class="text-sm">Nous ne sommes pas en mesure de vous afficher la page que vous demandez</p>
        <i class="fa-solid fa-triangle-exclamation fa-8x my-10 EoleYellow--text fa-beat"></i>
        <br />
        <v-btn class="white--text mt-10" rounded color="EoleBlue" to="dashboard"> Retourner à l'accueil </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>
